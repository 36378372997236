import { CabButton } from "@CabComponents/CabButton";
import { Alert, Box, Snackbar, SnackbarCloseReason, styled, Typography } from "@mui/material";
import { debounce } from "lodash-es";
import React, { ReactElement, SyntheticEvent, useEffect, useRef, useState } from "react";
import colors from "../../colors";


export interface CodeInputProps {
  length: number;
  label?: string;
  loading?: boolean;
  errorMessage?: string[];
  placeholder?: string;
  autofocus?: boolean;
  offerNewCode?: boolean;
  buttonText?: string;
  autoSubmit?: boolean;
  onResendCode: () => void;
  onComplete: (value: string) => void;
  toastMessage?: string;
  onClearToastMessage?: () => void;
}

const VerificationCodeInput = ({ length, placeholder, label, loading, errorMessage, onResendCode, 
  onComplete, offerNewCode, autoSubmit, buttonText, autofocus, toastMessage, onClearToastMessage
} : CodeInputProps): ReactElement => {
  const [code, setCode] = useState([...Array(length)].map(() => ''));
  const [codeError, setCodeError] = useState('');
  const [enteredLength, setEnteredLength] = useState(0);
  const [offerNewCodeDisabled, setOfferNewCodeDisabled] = useState(false);
  const inputs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    return () => { 
      setCode([...Array(length)].map(() => ''));
      setEnteredLength(0);
    };
  }, [length]);

  const handleReset = () => {
    setCode([...Array(length)].map(() => ''));
    setEnteredLength(0);
    inputs.current[0]?.focus();
  };

  const handleResetDebounced = debounce(handleReset, 500);

  const processInput = (e: React.ChangeEvent<HTMLInputElement>, slot: number) => {
    const num = e.target.value;
    if (/[^0-9]+/.test(num)) return;
    const newCode = [...code];
    const trimmedInput = num.slice(0, length - slot);
    newCode.splice(slot, trimmedInput.length, ...trimmedInput);
    setEnteredLength(slot + trimmedInput.length);
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + trimmedInput.length]?.focus();
    }
    if (newCode.every(codeNum => codeNum !== '')) {
      inputs.current[length - 1]?.focus();
      if (autoSubmit) {
        onComplete(newCode.join(""));
        handleResetDebounced();
      }
    }
  };

  const handleButtonClick = () => {
    const newCode = [...code];
    if (newCode.every(num => num !== '')) {
      setCodeError('');
      onComplete(newCode.join(""));
      handleResetDebounced();
    } else {
      if (newCode.every(num => num === '')) {
        setCodeError('Verification code cannot be blank.');
      } else {
        setCodeError('The verification code you entered is too short.');
      }
    }
  };

  const handleResendCode = () => {
    onResendCode();
    setOfferNewCodeDisabled(true);
    setTimeout(() => setOfferNewCodeDisabled(false), 30000);
  };

  const onKeyUp = (e: React.KeyboardEvent, slot: number) => {
    if ((e.keyCode === 8 || e.key === 'Backspace') && slot !== 0) {
      const newCode = [...code];
      if (code[slot]) {
        newCode[slot] = "";
        setEnteredLength(slot);
        setCode(newCode);
        inputs.current[slot]?.focus();
      } else {
        newCode[slot - 1] = "";
        setEnteredLength(slot - 1);
        setCode(newCode);
        inputs.current[slot - 1]?.focus();
      }
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2} width="100%">
        <Box marginLeft={2}>
          <Typography variant="body2" fontSize={16} fontWeight={500}>{label}</Typography>
          <Box>
            {code.map((num, idx) => {
              return (
                <StyledInput
                  key={idx}
                  readOnly={idx > enteredLength}
                  disabled={loading}
                  type="text"
                  inputMode="numeric"
                  placeholder={idx > enteredLength ? placeholder : ''}
                  value={num}
                  autoFocus={autofocus && !code[0].length && idx === 0}
                  onChange={e => processInput(e, idx)}
                  onKeyUp={e => onKeyUp(e, idx)}
                  ref={ref => inputs.current.push(ref)}
                />
              );
            })}
          </Box>
        </Box>
        {!autoSubmit && (
          <CabButton
            size="large"
            onClick={handleButtonClick}
            sx={{ width: '100%', maxWidth: '350px', marginTop: 2 }}
          >
            {buttonText || 'Submit'}
          </CabButton>
        )}
        {((errorMessage && errorMessage.length) || codeError) && (
          <>
            <Alert severity="error" sx={{ marginTop: 1, width: '360px' }}>
              {codeError ?
                <>
                  {codeError}
                </>
                : <div>
                  {errorMessage && errorMessage.map((err, idx) => (
                    <Box whiteSpace={'pre-wrap'} key={idx}>
                      {err}&nbsp;
                    </Box>
                  ))}
                </div>
              }
            </Alert>
          </>
        )}
        {offerNewCode && !offerNewCodeDisabled && (
          <Alert severity="info" sx={{ marginTop: 1, width: '360px' }}>
            <span style={{cursor:'pointer', textDecoration: 'underline'}} onClick={handleResendCode}>
              Request a new code
            </span>
          </Alert>
        )}

      </Box>
      <Snackbar
        open={!!toastMessage}
        onClose={(event: Event | SyntheticEvent, reason: SnackbarCloseReason) => {
          if (reason !== 'clickaway' && onClearToastMessage) {
            onClearToastMessage();
          }
        }}
        autoHideDuration={5000}
        message={toastMessage}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      />
    </>
  );
};

const StyledInput = styled("input", {label: "StyledInput"})({
  height: 80,
  width: 64,
  marginTop: 16,
  marginRight: 16,
  fontSize: 32,
  fontWeight: 700,
  textAlign: 'center',
  border: '1px solid',
  borderColor: colors.black800,
  borderRadius: 6,
  ':focus-visible': {
    outline: 'none',
  },
  ':read-only': {
    fontWeight: 400,
    fontSize: 30,
    borderColor: colors.black300,
  },
  '::placeholder':{
    color: colors.black300,
  }
});

export default VerificationCodeInput;